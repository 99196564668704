.navigation-bar-desktop .controls-wrapper{
    display: flex;
    width: 90%;
    margin: 0 auto;
    justify-content: space-between;
}
.navigation-bar-desktop .controls-wrapper .decoration{
    display: block;
}
.navigation-bar-desktop .controls{
    display: flex;
}
.navigation-bar-desktop{
    background-color: #FFC700;
    position: relative;
    box-shadow: 0px 4px 10px rgb(0 0 0 / 50%);
    z-index: 100;
}
.simehadrin .navigation-bar-desktop{
    background-color: #206881;
}
.navigation-bar-desktop .logo{
    height: 112px;
    margin-top: 34px;
}
.simehadrin .navigation-bar-desktop .logo{
    margin-top: 45px;
}
.navigation-bar-desktop .links-wrapper{
    margin-top: 126px;
    margin-right: 31px;
    display: flex;
}
.simehadrin .navigation-bar-desktop .links-wrapper{
    margin-top: 100px;
}
.navigation-bar-desktop .links-wrapper a{
    margin-left: 21px;
    color: #000;
    display: block;
}
.simehadrin .navigation-bar-desktop .links-wrapper a{
    color: #fff;
}
.navigation-bar-desktop .logout{
    text-decoration: none;
    margin-top: 126px;
    color: #000;
    border: 1px solid #000;
    height: 20px;
    border-radius: 24px;
    padding: 10px;
}
.simehadrin .navigation-bar-desktop .logout{
    color: #fff;
    border: 1px solid #fff;
}
.navigation-bar-desktop .logo-wrapper .text{
    color: #000;
    font-size: 9px;
    text-align: center;
    font-weight: bold;
}
.navigation-bar-desktop .logout-wrapper{
    margin-top: 130px;
}
.navigation-bar-desktop .welcome-bar .community-name{
    font-weight: bold;
}
.navigation-bar-desktop .welcome-bar .welcome{
    font-size: 18px;
}
.navigation-bar-desktop .welcome-bar .community *{
    font-size: 40px;
    margin-left: 10px;
}
.navigation-bar-desktop *{
    color: #000;
}
.simehadrin .navigation-bar-desktop *{
    color: #fff;
}
.navigation-bar-desktop .welcome-bar{
    position: absolute;
    margin-top: 68px;
}
.navigation-bar-desktop .active{
    font-weight: bold;
}
.navigation-bar-mobile{
    display: none;
    padding: 30px;
    background-color: #FFC700;
    box-shadow: 0px 1px 10px rgb(0 0 0 / 20%);
}
.simehadrin .navigation-bar-mobile{
    background-color: #206881;
}
.navigation-bar-mobile .welcome-bar .welcome{
    font-size: 16px;
}
.navigation-bar-mobile .welcome-bar .community *{
    font-size: 21px;
}
.navigation-bar-mobile .welcome-bar .community span{
    margin-left: 10px;
}
.navigation-bar-mobile .welcome-bar .community .community-name{
    font-weight: bold;
}
.navigation-bar-mobile .controls{
    display: flex;
    justify-content: space-between;
}
.navigation-bar-mobile .logo-link{
    display: block;
}
.mobile-navigation-controls a{
    display: block;
    font-size: 21px;
    margin-bottom: 16px;
    color: #000;
}
.simehadrin .mobile-navigation-controls a{
    color: #fff;
}
.mobile-navigation-controls a.active{
    font-weight: bold;
}
.mobile-navigation-controls .close{
    width: 30px;
    margin-bottom: 80px;
}
.mobile-navigation-controls .links-wrapper{
    margin-bottom: 57px;
}
.mobile-navigation-controls{
    width: 202px;
    box-shadow: 0px 0px 0px 9999px rgb(0 0 0 / 50%);
    background-color: #FFC700;
    position: absolute;
    top: 0;
    height: 100%;
    padding: 30px;
    z-index: 999;
}
.simehadrin .mobile-navigation-controls{
    background-color: #206881;
}
.hidden{
    display: none;
}
@media screen and (max-width: 1570px) {
    .navigation-bar-desktop .links-wrapper{
        margin-top: 120px;
    }
}
@media screen and (max-width: 1350px) {
    .navigation-bar-desktop .controls-wrapper{
        position: relative;
    }
    .navigation-bar-desktop .controls{
        width: 40%;
    }
    .navigation-bar-desktop .links-wrapper{
        margin-right: 31px;
        display: flex;
    }
    .navigation-bar-desktop .controls-wrapper .decoration{
        width: 60%;
    }
    .navigation-bar-desktop .logout-wrapper{
        position: absolute;
        left: 10px;
    }
    .navigation-bar-desktop .welcome-bar{
        margin-top: 48px;
    }
    .navigation-bar-desktop .welcome-bar .community *{
        font-size: 30px;
    }
}
@media screen and (max-width: 1200px) {
    .navigation-bar-desktop .links-wrapper{
        margin-top: 82px;
    }
    .navigation-bar-desktop .logo{
        margin-top: 13px;
        width: 60px;
        height: 98px;
    }
    .navigation-bar-desktop .logout-wrapper{
        left: -40px;
        margin-top: 95px;
    }
    .navigation-bar-desktop .welcome-bar {
        margin-top: 28px;
    }
}
@media screen and (max-width: 960px) {
    .navigation-bar-desktop{
        display: none;
    }
    .navigation-bar-mobile{
        display: block;
    }
}